<template>
  <div class="ip-text-input">
    <label
      v-if="withLabel"
      :for="id"
    >
      <span v-html="label"></span>
      <span
        v-if="showRequiredIcon"
        class="required-icon">
          *
      </span>
    </label>
    <input
      :class="[size]"
      :id="id"
      :name="name"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keypress.enter="$emit('onPressEnter')"
      :placeholder="placeholder"
      :invalid="invalid"
      :disabled="disabled"
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    showRequiredIcon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  }
};
</script>

<style scoped lang="scss">
label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $darkGray;
  margin-bottom: 3px;
  display: flex;
  ::v-deep .ip-tooltip{
    margin-left: 1px;
  }
}

input {
  display: block;
  outline: none !important;
  background: $white;
  border: 1px solid $lightGray;
  box-sizing: border-box;
  padding: 5px 12px;
  color: $darkGray;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  height: 30px;
  border-radius: 6px;
  &::placeholder {
    color: $midGray;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  &:focus {
    border-color: $blue;
  }

  &[disabled] {
    color: $midGray;
    background: $wildSand;
    border-color: $lightGray;
  }

  &[invalid] {
    border-color: $red;
  }

  &.big {
    padding: 12px;
    font-size: 14px;
    height: 44px;
  }
}
</style>
