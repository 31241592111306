<template>
  <div class="users">
    <div class="users__header">
      <div class="users__title">
        Клиенты
      </div>
      <div class="users__filters">
        <ip-text-input 
          name="search"
          id="search"
          label="Поиск"
          placeholder="Введите номер телефона или ФИО"
          v-model="searchQuery"
        />
        <ip-select
          :options="roles"
          label="Роль"
          placeholder="Все"
          @onSelect="selectRole"
          :selected-item="role">
        </ip-select>
        <ip-select
          :options="statuses"
          label="Статус"
          placeholder="Все"
          @onSelect="selectStatus"
          :selected-item="status">
        </ip-select>
      </div>
    </div>
    <div class="users__table">
      <div class="table">
        <div class="table__head">
          <div class="table__col">
            Статус
          </div>
          <div class="table__col">
            Роль
          </div>
          <div class="table__col">
            Клиент
          </div>
          <div class="table__col">
            N телефона
          </div>
          <div class="table__col">
            Дата регистрации
          </div>
        </div>
        <div class="table__body" v-if="isLoading">
          <div class="loader-container">
            <ip-loader />
          </div>
      </div>
        <div class="table__body" v-else>
          <router-link
            v-for="client in clients"
            :key="client.id"
            class="table__row"
            :to="{ name: 'user', params: { id: client.id } }"
            >
            <div class="table__col">
              <div
                class="status"
                :class="{'inactive': client.banned}">
              </div>
            </div>
            
            <div class="table__col">
              <div v-if="client.type === 'owner'" class="type-block">
                <inline-svg :src="require('../assets/icons/home_24px.svg')" />
                Owner
              </div>
              <div v-else class="type-block">
                <inline-svg :src="require('../assets/icons/account_circle_24px_outlined.svg')" />
                Tenant
              </div>
            </div>
            <div class="table__col">
              {{ getUserName(client) }}
            </div>
            <div class="table__col">
              {{ client.phone }}
            </div>
            <div class="table__col">
              {{ client.registeredAt | moment("DD.MM.YYYY") }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="users__pagination">
      <ip-pagination
        :total="total" 
        :offset="offset"
        :limit="limit"
        :current-page="currentPage"
        @onChangePage="changePage"/>
    </div>
  </div>
</template>

<script>
import IpSelect from "../components/IpSelect";
import IpTextInput from "../components/IpTextInput";
import IpPagination from "../components/IpPagination";
import IpLoader from "../components/IpLoader";
import apiRoutes from '../consts/apiRoutes';
import CommonMixin from "../mixins/Common.mixin";

export default {
  name: "users",

  mixins: [CommonMixin],

  components: {
    IpSelect,
    IpPagination,
    IpLoader,
    IpTextInput
  },

  data() {
    return {
      searchQuery: '',
      roles: [
        {
          value: 'all',
          text: 'Все'
        },
        {
          value: 'owner',
          text: 'owner'
        },
        {
          value: 'tenant',
          text: 'tenant'
        }
      ],
      statuses: [
        {
          value: 'all',
          text: 'Все'
        },
        {
          value: 'open',
          text: 'Требует выполнения'
        },
        {
          value: 'closed',
          text: 'Выполнены'
        }
      ],
      status: 'all',
      role: 'all',
      clients: [],
      isLoading: true,
      limit: 14,
      offset: 0,
      total: 0,
      currentPage: 1,
      timer: null
    };
  },

  async mounted() {
    await this.fetchClients();
  },

  watch: {
    searchQuery() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.currentPage = 1;
        this.fetchClients();
      }, 500);
    }
  },

  methods: {
    async fetchClients() {
      this.isLoading = true;
      const data = {
        limit: this.limit,
        offset: this.limit * (this.currentPage - 1),
        role: this.role,
        status: this.status,
        search: this.searchQuery
      };

      if (this.role === 'all') {
        delete data.role;
      }

      if (this.status === 'all') {
        delete data.status;
      }

      try {
        const result = await this.axios.post(apiRoutes.clients, data);
        this.clients = result.data.clients;
        this.total = result.data.total;
        this.isLoading = false;
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    },

    selectRole(role) {
      this.role = role;
      this.currentPage = 1;
      this.fetchClients();
    },

    selectStatus(status) {
      this.status = status;
      this.currentPage = 1;
      this.fetchClients();
    },

    changePage(page) {
      this.currentPage = page;
      this.fetchClients();
    }
  }
}
</script>

<style scoped lang="scss">
  .users{
    border-radius: 4px;
    background: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__header{
      display: flex;
      align-items: flex-end;
      padding: 19px 24px;
      flex: none;
    }
    &__title{
      margin-right: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 36px;
      color: $black;
      flex: none;
    }
    &__filters{
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ::v-deep .ip-select{
        width: 200px;
        margin-left: 10px;
      }
      ::v-deep .ip-text-input{
        flex: 1 1 auto;
      }
    }
    &__table{
      flex: 1 1 auto;
      overflow: hidden;
      .table{
        height: 100%;
        .table__row{
          text-decoration: none;
        }
        .table__col{
          width: 22%;
          &:first-child{
            width: 12%;
          }
        }
      }
      .type-block{
        display: flex;
        align-items: center;
        ::v-deep svg{
          margin-right: 16px;
        }
      }
      .status{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $persianGreen;
        &.inactive{
          background: $persianRed;
        }
      }
    }
    &__pagination{
      border-top: 1px solid $alto;
      flex: none;
      padding: 0 24px;
    }
  }
</style>