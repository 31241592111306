<template>
  <div
    v-if="!!total"
    class="ip-pagination">
      <div class="buttons-container">
        <div class="buttons">
        <div
          class="button"
          :class="{'disabled': currentPage === 1}"
          @click="$emit('onChangePage', 1)">
          <inline-svg
            :src="require('../assets/icons/first_page_24px_outlined.svg')"
          ></inline-svg>
        </div>
        <div
          class="button"
          :class="{'disabled': currentPage === 1}"
          @click="prevPage">
          <inline-svg
            :src="require('../assets/icons/chevron_left_24px_outlined.svg')"
          ></inline-svg>
        </div>
      </div>
      <div class="pages">
        <div
          v-for="(page, index) in pages"
          :key="index"
          class="button"
          :class="{
            'separator': page === 'sep',
            'active': page === currentPage || isOnPage(page),
          }"
          @click="$emit('onChangePage', page)">
          {{page === 'sep' ? '...' : page}}
        </div>
      </div>
      <div class="buttons">
        <div
          class="button"
          :class="{'disabled': currentPage === pageCount}"
          @click="nextPage">
          <inline-svg
            :src="require('../assets/icons/chevron_right_24px_outlined.svg')"
          ></inline-svg>
        </div>
        <div
          class="button"
          :class="{'disabled': currentPage === pageCount}"
          @click="$emit('onChangePage', pageCount)">
          <inline-svg
            :src="require('../assets/icons/last_page_24px_outlined.svg')"
          ></inline-svg>
        </div>
      </div>

    </div>
    <div class="pages-container">
      Стр. {{ currentPage }} из {{ pageCount }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    }
  },

  computed: {
    /**
     * @return {number}
     */
    pageCount () {
      return Math.ceil(this.total / this.limit);
    },

    /**
     * @return {array}
     */
    pages () {
      if (this.pageCount <= 7) {
        return new Array(this.pageCount).fill(null).map((page, index) => index + 1);
      }

      if (this.currentPage < 4) {
        const pages = [];

        for (let i = this.currentPage + 1; i >= 1 && pages.length < 4; i--) {
          pages.unshift(i)
        }

        for (let i = this.currentPage + 2; pages.length < 5; i++) {
          pages.push(i);
        }

        pages.push('sep', this.pageCount);

        return pages;
      }

      if (this.currentPage >= this.pageCount - 4) {
        const pages = [1, 'sep'];

        for (let i = this.pageCount - 5; i <= this.pageCount; i++) {
          pages.push(i)
        }

        return pages;
      }

      return [1, 'sep', this.currentPage - 1, this.currentPage, this.currentPage + 1, 'sep', this.pageCount];
    }
  },

  methods: {
    /**
     * @return {void}
     */
    prevPage () {
      if (this.currentPage === 1) {
        return;
      }
      this.$emit('onChangePage', this.currentPage - 1);
    },

    /**
     * @return {void}
     */
    nextPage () {
      if (this.currentPage === this.pageCount) {
        return;
      }
      this.$emit('onChangePage', this.currentPage + 1);
    },

    /**
     * @param {number} page
     * @return {boolean}
     */
    isOnPage (page) {
      const pagesOffset = (this.offset / this.limit) + 1;
      return this.currentPage - pagesOffset < page && page <= this.currentPage;
    }
  }
}
</script>

<style scoped lang="scss">
.ip-pagination{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons-container{
    display: inline-flex;
    justify-content: space-between;
    height: 79px;
  }
  .pages-container{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: $black;
  }
  .buttons{
    display: flex;
    align-items: center;
  }
  .button{
    width: 50px;
    height: 36px;
    margin: 0 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $aquaHaze;
    border-radius: 6px;
    &.disabled{
      background: $midGray;
      pointer-events: none;
    }
    &:hover{
      cursor: pointer;
      background: $scienceBlue;
      ::v-deep path{
        fill: $white;
      }
    }
  }
  .pages{
    display: flex;
    align-items: center;
    .button{
      font-size: 14px;
      line-height: 17px;
      color: $black;
      background: $aquaHaze;
      &.active,
      &.active-more{
        background: $scienceBlue;
        color: $white;
      }
      &.separator{
        background: none;
        pointer-events: none;
        &:hover {
          background: none;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
